<template>
  <transition name="fade-in-up">
    <div>
      <div class="card card-custom gutter-b">
        <div class="card-body">
          <v-row class="mb-5">
            <v-col>
              <v-btn
                color="primary"
                large
                :outlined="active != 'kendaraan'"
                small
                class="mr-5"
                @click="active = 'kendaraan'"
                >Produksi Kendaraan</v-btn
              >
              <v-btn
                color="primary"
                large
                :outlined="active != 'penumpang'"
                small
                @click="active = 'penumpang'"
                >Produksi Penumpang</v-btn
              >
            </v-col>
          </v-row>
          <list-kendaraan v-if="active == 'kendaraan'"></list-kendaraan>
          <list-penumpang v-if="active == 'penumpang'"></list-penumpang>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ListKendaraan from "./ListKendaraan.vue";
import ListPenumpang from "./ListPenumpang.vue";

export default {
  mounted() {
    if (!this.pob_id) {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Dashboard", route: "" },
        { title: "Produksi Kendaraan" },
      ]);
    }
  },
  data() {
    return {
      active: "kendaraan",
    };
  },
  watch: {
    active(val) {
      if (val == "kendaraan") {
        this.$store.dispatch(SET_BREADCRUMB, [
          { title: "Dashboard", route: "" },
          { title: "Produksi Kendaraan" },
        ]);
      } else {
        this.$store.dispatch(SET_BREADCRUMB, [
          { title: "Dashboard", route: "" },
          { title: "Produksi Penumpang" },
        ]);
      }
    },
  },
  methods: {
    // setActiveTab(tipe){
    // }
  },
  components: {
    ListKendaraan,
    ListPenumpang,
  },
};
</script>

<style scoped>
.table-bordered th,
.table-bordered td {
  border: 1px solid #ddd;
}
</style>
