<template>
  <div>
    <v-row>
      <v-col cols="12" sm="6" md="4">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="date"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateRangeText"
              label="Tanggal"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              outlined
              dense
              clearable
            ></v-text-field>
          </template>
          <v-date-picker v-model="dates" no-title scrollable range>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false">
              Cancel
            </v-btn>
            <v-btn text color="primary" @click="$refs.menu.save(date)">
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-simple-table class="table-bordered" dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-center" colspan="8">Tanggal {{ dateRangeText }}</th>
          </tr>
          <tr>
            <th class="text-center" rowspan="2">Terminal</th>
            <th class="text-center" rowspan="2">Tanggal</th>
            <th class="text-center" colspan="2">AKAP</th>
            <th class="text-center" colspan="2">AKDP</th>
            <th class="text-center" colspan="2">Total Penumpang</th>
          </tr>
          <tr>
            <th>Kedatangan</th>
            <th>Keberangkatan</th>
            <th>Kedatangan</th>
            <th>Keberangkatan</th>
            <th>Kedatangan</th>
            <th>Keberangkatan</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" :key="item.name">
            <td>{{ item.name }}</td>
            <td>{{ item.tanggal | moment("dddd, MMMM Do YYYY") }}</td>
            <td>{{ item.akap_in }}</td>
            <td>{{ item.akap_out }}</td>
            <td>{{ item.akdp_in }}</td>
            <td>{{ item.akdp_out }}</td>
            <td>{{ parseInt(item.akap_in) + parseInt(item.akdp_in) }}</td>
            <td>{{ parseInt(item.akap_out) + parseInt(item.akdp_out) }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <div class="text-right ml-auto mt-5">
      <v-pagination
        v-model="currentPage"
        :length="totalPage"
        circle
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import moment from "moment";

export default {
  mounted() {
    this.getData();
  },
  data() {
    return {
      loading: true,
      totalItems: 0,
      currentPage: 1,
      perPage: 10,
      totalPage: 0,
      items: [],
      dates: [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
      dataColumn: [
        { data: "name" },
        {
          data: "tanggal",
        },
        {
          data: "akap_in",
        },
        {
          data: "akap_out",
        },
        {
          data: "akdp_in",
        },
        {
          data: "akdp_out",
        },
      ],
    };
  },
  methods: {
    async getData() {
      const params = {
        columns: this.dataColumn,
        page: this.currentPage,
        perPage: this.perPage,
        order: {
          column: "tanggal",
          dir: "asc",
        },
      };
      if (this.dates.length == 2) {
        params.date = this.dates;
      }
      const data = await ApiService.post(
        "/dashboard-operasional-kendaraan",
        params
      );

      this.loading = false;

      this.items = data.data.data;
      this.totalItems = data.data.recordsTotal;
      this.totalPage = Math.ceil(this.totalItems / this.perPage);
    },
  },
  watch: {
    currentPage() {
      this.getData();
    },
    dates() {
      this.getData();
    },
  },
  computed: {
    dateRangeText() {
      return (
        moment(this.dates[0]).format("Do MMM YYYY") +
        " s/d " +
        moment(this.dates[1]).format("Do MMM YYYY")
      );
    },
  },
};
</script>

<style></style>
